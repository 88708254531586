<template>

    <div v-if="data.name != 'Agregado'" class="h-auto mb-4 relative">

        <div v-if="!deletemode" class="h-auto w-full rounded-lg flex flex-col overflow-hidden" style="transition: .3 all" @click="openUser()">

            <div class="h-16 w-full flex flex-row justify-start items-center relative px-4">

                <div class="h-8 w-8 flex-none mr-2 rounded-full flex flex-col justify-center items-center" :class="'bg-darkgreen-'+modetheme">
                    <span class="text-white text-lg font-bold">{{ data.name.substr(0,1) }}</span>
                </div>

                <i v-if="data.admin == 1 && view != 'useApp'" class="mdi mdi-star text-red text-xl ml-1 absolute top-0 left-0"></i>

                <div class="h-full w-full overflow-hidden flex flex-col justify-center items-start">

                    <div class="h-auto w-full flex flex-row justify-start items-center overflow-hidden">
                        <span class="text-md font-semibold truncate" :class="'text-dfont-'+modetheme">{{data.name}} {{data.surname1}} {{data.surname2}}</span>
                    </div>
                    <div class="h-auto w-full flex flex-row justify-start items-center">
                        <span v-if="data.email" class="text-sm" :class="'text-font_gray-'+modetheme">{{data.email}}</span>
                    </div>

                </div>

                <div class="h-full w-6 flex flex-row justify-center items-center" >

                    <div v-if="data.name != 'Agregado' && view != 'useApp'" class="h-10 w-10 absolute top-0 right-0 flex z-10 flex-row justify-center items-center" @click="mode = !mode">
                        <i v-if="!mode" class="mdi mdi-dots-vertical" :class="'text-dfont-'+modetheme"></i>
                        <i v-if="mode" class="mdi mdi-close" :class="'text-dfont-'+modetheme"></i>
                    </div>                    

                </div>
                
                <!-- <div class="h-10 w-10 absolute z-10 top-0 right-0 flex flex-row justify-center items-center" >

                    <div v-if="data.name != 'Agregado' && view != 'useApp'" class="h-10 w-10 absolute top-0 right-0 flex z-50 flex-row justify-center items-center" @click="mode = !mode">
                        <i v-if="!mode" class="mdi mdi-dots-vertical" :class="'text-dfont-'+modetheme"></i>
                        <i v-if="mode" class="mdi mdi-close" :class="'text-dfont-'+modetheme"></i>
                    </div>                    

                </div>

                <div v-if="data.name != 'Agregado'" class="h-auto w-2/12 flex flex-col justify-center items-center relative">

                    

                </div>

                <div class="h-auto py-4 flex flex-col justify-center items-start" :class="{'w-full px-2':data.name == 'Agregado', 'w-10/12': data.name != 'Agregado'}">

                    <span class="text-md font-semibold" :class="'text-dfont-'+modetheme">{{data.name}} {{data.surname1}} {{data.surname2}}</span>
                    <span v-if="data.email" class="text-sm" :class="'text-font_gray-'+modetheme">{{data.email}}</span>

                </div> -->

            </div>

            <div class="overflow-hidden" :class="{'h-0': !mode, 'h-20': mode}" style="transition: all .3s">

                <div v-if="mode" class="h-20 w-full flex flex-row absolute">

                    <div class="h-full w-1/2 flex flex-row justify-center items-center">

                        <Request
                        model="DeleteUser"
                        action="delete"
                        :form="{id:data.id}"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }">

                            <div  v-if="!loading" class="h-8 w-6/12 bg-red rounded-md flex flex-row justify-center items-center" @click="request()">
                                <span class="text-white text-lg font-semibold">{{$t('delete')}}</span>
                            </div>

                        </Request>

                    </div>

                    <div class="h-full w-1/2 flex flex-row justify-center items-center">

                        <div class="h-8 w-6/12 bg-purple rounded-md flex flex-row justify-center items-center" @click="editUser(data.id)">
                            <span class="text-white text-lg font-semibold">{{$t('edit')}}</span>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div v-else class="h-16 mb-4 relative shadow_box rounded-lg overflow-hidden bg-red" @click="openUser()">

        <div class="h-full w-full mx-auto flex flex-row overflow-hidden">

            <div class="h-full w-full flex flex-row relative">

                <div class="h-auto w-full py-4 flex flex-col justify-center items-center">

                    <span class="text-sm font-semibold text-white">{{data.name}} {{data.surname1}} {{data.surname2}}</span>

                    <div v-if="company" class="h-auto -mt-1">

                        <All model="BackCompany" :immediate="true" :data.sync="companies" v-slot="{data:datacompany,loading:loadingcompany}">
                            <div v-if="!loadingcompany" class="h-auto">
                                <span v-for="(el,index) in datacompany" :key="index" v-if="company == el.Id" class="text-sm text-white font-semibold">{{el.FrontName}}</span>
                            </div>
                        </All>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { state, actions } from '@/store';
import { All, Request } from '@/api/components';

export default {
    props:['data', 'view', 'company'],
    components:{
        All,
        Request
    },
    data(){
        return{
            deletemode:false,
            mode:false,
            companies:false

        }
    },
    methods:{
        editUser(user){

            actions.setUserToEdit(user)
            this.$router.push( { name: 'edituser' } )

        },
        deleteUser(){

            this.deletemode = true

        },
        dedeleteUser(option){

            this.deletemode = false

        },
        onSuccess(){
            this.$message({
                message: this.$t("relationDeleted"),
                type: 'success'
            });
            this.$emit('reload')
        },
        onError(){
            this.$message.error('Error');
        },
        openUser(){
            
            if(this.view && this.view == 'useApp'){

                actions.setUserAnalitycs(this.data)
                this.$router.push('analitycsUser')

            }

        },
        color(value){

            if(value){

                for (let index = 0; index < this.companies.length; index++) {
                    
                    if(this.companies[index].Id == this.company){

                        switch (this.companies[index].IdLink) {
                            case 1:
                                return 'bg-red'
                                break;
                            case 28:
                                return 'bg-geotiles'
                                break;
                            case 30:
                                return 'bg-prissmacer'
                                break;
                            case 31:
                                return 'bg-ecoceramic'
                                break;
                            case 32:
                                return 'bg-navarti'
                                break;
                            case 36:
                                return 'bg-tauceramica'
                                break;
                            case 'Propiedad':
                                return 'bg-red'
                                break;
                            case 'Representante':
                                return 'bg-red'
                                break;
                        }

                    }
                    
                }
                

            } else {
                return 'bg-red'
            }

        }
    },
    computed:{
        modetheme(){
            return state.mode
        }
    },
    mounted(){
    }
}
</script>
